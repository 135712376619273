.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #242730;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-main {
  display: flex;
  flex-direction: column;
}
.App-main__canvas-container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
  min-width: 500px;
  min-height: 500px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.App-main__canvas-container__canvas {
  width: 100%;
  height: 100%;
}
.App-main__pilot-list__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 600px;
}
.App-main__pilot-list__container__pilot-list {
  width: 100%;
  overflow: scroll;
  margin-bottom: 2rem;
}

.App-main__pilot-list__container__pilot-list__headers {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(6, 1fr);
  padding: 0 5%;
  font-size: 1.4rem;
  font-weight: bold;
}
.App-main__pilot-list__container__pilot-list__item {
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(6, 1fr);
  padding: 0 5%;
  font-size: 1.2rem;
  word-break: break-word;
  overflow: scroll;
  background-color: #259faf;
}
.App-main__pilot-list__container__pilot-list__item:nth-child(even) {
  background-color: #24273047;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
